import React, { useState } from "react";
import "./AboutActivityBottonSection.scss";
import { CalendarMonth } from "@mui/icons-material";
import { activityType, requestType } from "../../types/globalTypes";
import moment from "moment";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import actvity_api from "../../_service/api.caller/activityCaller";
import _, { capitalize } from "lodash";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const AboutActivityBottonSection = () => {
  const [data, setData] = useState<activityType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    actvity_api.activityItem
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  let orderedData = _.orderBy(data, ["activity_created_at"], ["desc"]);

  React.useEffect(() => {
    fetchAll();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }

  return (
    <>
      {/* Blog Start */}
      <div className="container py-5">
        <div className="row g-5 justify-content-center">
          {orderedData.slice(0, 4).map((value, index) => {
            let img_url: string;
            if (!!value.path_img) {
              let array = value.path_img.split("/");
              let array_2: any = [];
              for (let i = 1; i < array.length; i++) {
                array_2.push(array[i]);
              }

              let url: string = array_2.join("/");
              img_url = `${URL_BACKEND_APP}/${url}`;
            } else {
              img_url = "img/blog-1.jpg";
            }

            return (
              <>
                {!!value.activity_status && (
                  <div
                    className="col-lg-3 wow "
                    data-wow-delay="0.3s"
                    key={index}
                  >
                    <div
                      className={
                        darkMode
                          ? "blog-item dark__activity rounded overflow-hidden"
                          : "blog-item bg-light rounded overflow-hidden"
                      }
                    >
                      {" "}
                      <div className="blog-img position-relative overflow-hidden img_container_about">
                        <img
                          className="img-fluid img_Activity"
                          src={img_url}
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href=""
                        >
                          {value?.category?.toUpperCase()}
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-2">
                          <small>
                            <CalendarMonth className="text-primary fs-5 mx-2" />
                            {moment(value.activity_created_at).format("L")}
                          </small>
                        </div>
                        <p
                          className="mb-2"
                          style={{ fontWeight: "bold", fontSize: "14px" }}
                        >
                          {capitalize(value.title.slice(0, 20))}...
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
      {/* Blog Start */}
    </>
  );
};

export default AboutActivityBottonSection;
