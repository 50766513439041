import React, { useRef } from "react";
import "./serviceList.scss";
import { ArrowForwardSharp, BusinessCenter } from "@mui/icons-material";
import { APP_CONST, ERROR_MESSAGE } from "../../_service/_const";
import { Link } from "react-router-dom";
import service_api from "../../_service/api.caller/serviceDoCaller";
import {
  lireInfoType,
  requestType,
  serviceTitleType,
  serviceType,
} from "../../types/globalTypes";
import lireInfo_api from "../../_service/api.caller/lireCaller";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const ServiceList = () => {
  const [dataTitle, setDataTitle] = React.useState<serviceTitleType[]>([]);
  const [dataItem, setDataItem] = React.useState<serviceType[]>([]);
  const [dataLire, setDataLire] = React.useState<lireInfoType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const fetchServiceTitle = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    service_api.serviceTitle
      .getAll()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataTitle(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  const fetchServiceItem = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    service_api.serviceItem
      .getAll()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataItem(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  const fetchLireInfo = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    lireInfo_api
      .getAll()
      .then((response) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setDataLire(response.data.data);
      })
      .catch((error: any) => {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  React.useEffect(() => {
    fetchServiceTitle();
    fetchServiceItem();
    fetchLireInfo();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }
  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-0">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              {dataTitle[0]?.subtitle}
            </h5>
            <h1 className="mb-0">{dataTitle[0]?.title}</h1>
          </div>
          <div className="row g-5">
            {dataItem.map((value, index) => {
              return (
                <>
                  {!!value.serviceItem_status && (
                    <div
                      className="col-lg-4 col-md-6 wow zoomIn"
                      data-wow-delay="0.3s"
                      key={index}
                    >
                      <div
                        className={
                          darkMode
                            ? "service-item dark__service rounded d-flex flex-column align-items-center justify-content-center text-center"
                            : "service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                        }
                      >
                        <div className="service-icon">
                          <BusinessCenter className="text-light" />{" "}
                        </div>
                        <h5 className="mb-3">{value.title}</h5>
                        <p className="m-0 text-justify">
                          {value.content.slice(0, 50)}
                        </p>
                        {/* <a className="btn btn-lg btn-primary rounded" href="">
                          <ArrowForwardSharp />{" "}
                        </a> */}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            {/* <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <BusinessCenter className="text-light" />{" "}
                </div>
                <h4 className="mb-3">Business Optimization</h4>
                <p className="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <ArrowForwardSharp />{" "}
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <BusinessCenter className="text-light" />{" "}
                </div>
                <h4 className="mb-3">Business Optimization</h4>
                <p className="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <ArrowForwardSharp />{" "}
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <BusinessCenter className="text-light" />{" "}
                </div>
                <h4 className="mb-3">Business Optimization</h4>
                <p className="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <ArrowForwardSharp />{" "}
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <BusinessCenter className="text-light" />{" "}
                </div>
                <h4 className="mb-3">Business Optimization</h4>
                <p className="m-0">
                  Amet justo dolor lorem kasd amet magna sea stet eos vero lorem
                  ipsum dolore sed
                </p>
                <a className="btn btn-lg btn-primary rounded" href="">
                  <ArrowForwardSharp />{" "}
                </a>
              </div>
            </div> */}

            <div
              className="col-lg-4 col-md-6 wow zoomIn "
              data-wow-delay="0.9s"
            >
              <Link to="/request">
                <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5 contact_Frame">
                  <h6 className="text-white mb-3">Call Us For A Resquest</h6>
                  <p className="text-white mb-3">
                    We are dedicated to providing exceptional customer service
                    and support.
                  </p>
                  <h5 className="text-white mb-0 ">{dataLire[0]?.telephone}</h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceList;
