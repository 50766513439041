import React from "react";
import "./footer.scss";
import { APP_CONST, ERROR_MESSAGE } from "../../_service/_const";
import {
  EmailOutlined,
  Facebook,
  Instagram,
  LinkedIn,
  Phone,
  PlaceOutlined,
  Twitter,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { LOGO } from "../../assets";
import { useNavigate } from "react-router-dom";
import { lireInfoType, requestType } from "../../types/globalTypes";
import lireInfo_api from "../../_service/api.caller/lireCaller";
import { t } from "i18next";
import { getToken } from "../../_utils/fonctions";
import newsletter_api from "../../_service/api.caller/newsLetterCaller";

const Footer = () => {
  let currentYear = new Date().getFullYear();
  const [data, setData] = React.useState<lireInfoType[]>([]);
  const [email, setEmail] = React.useState<string>("");

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const navigate = useNavigate();

  const fetchAll = () => {
    // let token = getToken();
    // if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    lireInfo_api
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };

  const handleCreate = () => {
    // console.log("Creating...");

    if (email.toString().length < 1) {
      return setState({
        isLoading: false,
        errorMessage: "Email address must not be empty",
      });
    }

    setState({
      isLoading: false,
      errorMessage: null,
    });

    // let token = getToken();
    // if (token === null) return navigate("/login");

    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    newsletter_api
      .create({
        email,
      })
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "sent",
        }));
        setEmail("");
      })
      .catch((error: any) => {
        // console.log(error);
        setEmail("");
        if (error.response.data && error.response.data.status === 401) {
          return setState({
            isLoading: false,
            errorMessage: "You're registered yet",
          });
        }
        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: "Connexion Error,  try again later",
          });
        }
        setState({
          isLoading: false,
          errorMessage: "Error on loading, try again later",
        });
      });
  };

  React.useEffect(() => {
    fetchAll();
  }, []);
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid text-light mt-5 wow bg_footer"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg_Footer_square p-4">
                <Link to="/" className="navbar-brand">
                  <h1
                    className="m-0 text-white"
                    style={{
                      fontFamily: "OrbitronSemiBold",
                      fontSize: "20px",
                    }}
                  >
                    <img src={LOGO} alt="logo" className="logo" />
                    {data[0]?.lire_designation}
                  </h1>
                </Link>
                <p className="mt-3 mb-4">
                  {/* {t("vision.content")} */}
                  Our vision is to be the premier catalyst for innovation and
                  success by linking ideas to results.
                  {/* We envision a future
                  where our organizatiion serves as a powerful bridge,
                  connecting creative ideas with tangible outcomes, and driving
                  transformative change across industries and markets. */}
                </p>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control border-white p-3"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="btn text-white"
                    style={{ backgroundColor: "#151515" }}
                    onClick={handleCreate}
                  >
                    Register
                  </button>
                </div>
                {!!state.errorMessage && (
                  <span className="text-light bg-danger mt-3 px-3">
                    {state.errorMessage}
                  </span>
                )}
                {!state.errorMessage && state.succussMessage === "sent" && (
                  <span
                    style={{
                      padding: "2px 4px",
                      color: "#fff",
                      backgroundColor: "#15e823",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    You subscribed to our news letter
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2 gap-1">
                    <PlaceOutlined />
                    <p className="mb-0">{data[0]?.adresse}</p>
                  </div>
                  <div className="d-flex mb-2 gap-1">
                    <EmailOutlined />
                    <p className="mb-0">{data[0]?.email}</p>
                  </div>
                  <div className="d-flex mb-2 gap-1">
                    <Phone />
                    <p className="mb-0">{data[0]?.telephone}</p>
                  </div>
                  <div className="d-flex mt-4">
                    {!!data[0]?.twitter && (
                      <a
                        className="btn btn-primary btn-square me-2"
                        href={data[0]?.twitter}
                      >
                        <Twitter />
                      </a>
                    )}

                    {!!data[0]?.facebook && (
                      <a
                        className="btn btn-primary btn-square me-2"
                        href={data[0]?.facebook}
                      >
                        <Facebook />
                      </a>
                    )}
                    {!!data[0]?.linkden && (
                      <a
                        className="btn btn-primary btn-square me-2"
                        href={data[0]?.linkden}
                      >
                        <LinkedIn />
                      </a>
                    )}
                    {!!data[0]?.instagram && (
                      <a
                        className="btn btn-primary btn-square me-2"
                        href={data[0]?.instagram}
                      >
                        <Instagram />
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/">
                      Home
                    </Link>
                    <Link className="text-light mb-2" to="/about">
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/service">
                      Services
                    </Link>
                    <Link className="text-light mb-2" to="/team">
                      Meet The Team
                    </Link>

                    <Link className="text-light" to="/contact">
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/request">
                      Request
                    </Link>
                    {/* <Link className="text-light mb-2" to="/about">
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/service">
                      Services
                    </Link>
                    <Link className="text-light mb-2" to="/team">
                      Meet The Team
                    </Link>

                    <Link className="text-light" to="/contact">
                      Contact Us
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#151515" }}
        // style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75px" }}
              >
                <p className="mb-0">
                  &copy; {currentYear} &nbsp;
                  <Link className="text-white border-bottom" to="/">
                    {data[0]?.lire_designation}
                  </Link>
                  &nbsp;. All Rights Reserved.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}

      {/* Back to Top */}
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default Footer;
