import "./vision.scss";

const Vision = () => {
  return (
    <>
      <section
        id="call-to-action"
        className="call-to-action section bg-gradient"
      >
        <img
          src="img/carousel-1.jpg"
          alt="bg_image"
          style={{ filter: "blur(8px)" }}
        />

        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="col-xl-10">
              <div className="text-center">
                <h3 className="text-light">Our Company Vision</h3>
                <p className="text-light">
                  The vision of our company is to be the premier catalyst for
                  innovation and success by linking ideas to results. We
                  envision a future where our company serves as a powerful
                  bridge, connecting creative ideas with tangible outcomes, and
                  driving transformative change across industries and markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vision;
