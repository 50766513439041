import React, { useState } from "react";
import "./teamMember.scss";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { requestType, teamType } from "../../types/globalTypes";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import team_api from "../../_service/api.caller/teamCaller";
import { capitalize, upperCase } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const TeamMember = () => {
  const [data, setData] = useState<teamType[]>([]);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const fetchAll = () => {
    setState({
      isLoading: true,
      errorMessage: null,
      succussMessage: "",
    });

    team_api
      .getAll()
      .then((response) => {
        // console.log(response);
        setState((prev) => ({
          ...prev,
          isLoading: false,
          succussMessage: "success",
        }));
        setData(response.data.data);
      })
      .catch((error: any) => {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
      });
  };
  React.useEffect(() => {
    fetchAll();
  }, []);

  let themeSelected = useSelector(
    (state: RootState) => state.themeMode.darkMode
  );
  let theme = localStorage.getItem("themeMode") || "darkMode";
  let darkMode: boolean = false;
  if (theme && theme === "darkMode") {
    darkMode = true;
  } else if (theme && theme === "lightMode") {
    darkMode = false;
  } else {
    darkMode = themeSelected;
  }

  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Team Members
            </h5>
            <h1 className="mb-0">
              Professional Stuffs Ready to Help Your Business
            </h1>
          </div>
          <div className="row g-5">
            {data.map((value, index) => {
              let imgURL: string;
              if (!!value.path_img) {
                let array = value.path_img.split("/");
                let array_2: any = [];
                for (let i = 1; i < array.length; i++) {
                  array_2.push(array[i]);
                }

                let url: string = array_2.join("/");
                imgURL = `${URL_BACKEND_APP}/${url}`;
              } else {
                imgURL = "img/team-1.jpg";
              }

              return (
                <>
                  {!!value.team_status ? (
                    <div
                      className="col-lg-4 wow slideInUp"
                      data-wow-delay="0.3s"
                      key={index}
                    >
                      <div
                        className={
                          darkMode
                            ? "blog-item dark__activity rounded overflow-hidden"
                            : "blog-item bg-light rounded overflow-hidden"
                        }
                      >
                        {" "}
                        <div className="team-img position-relative overflow-hidden img_cpntainer">
                          <img
                            className="img-fluid w-100 img_Team"
                            src={imgURL}
                            alt=""
                          />
                          <div className="team-social">
                            <a
                              className="btn btn-lg btn-primary btn-lg-square rounded"
                              href={!!value.linkden ? value.linkden : ""}
                            >
                              <LinkedIn />
                            </a>
                            <a
                              className="btn btn-lg btn-primary btn-lg-square rounded"
                              href={value.facebook ? value.facebook : ""}
                            >
                              <Facebook />
                            </a>
                            <a
                              className="btn btn-lg btn-primary btn-lg-square rounded"
                              href={value.instagram ? value.instagram : ""}
                            >
                              <Instagram />
                            </a>
                          </div>
                        </div>
                        <div className="text-center py-4">
                          <h4 className="text-primary">{`${capitalize(
                            value.nom
                          )} ${upperCase(value.postnom)}`}</h4>
                          <p className=" text-center text-uppercase m-0">
                            {capitalize(value.tache)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMember;
